import * as React from 'react'
import NavHeader from '../components/NavHeader'
import HeroArea from '../components/fiveJBR/heroArea'
import Connectivity from '../components/fiveJBR/connectivities'
import Amenities from '../components/fiveJBR/amenities'
import Plans from '../components/fiveJBR/plans'
import Footer from '../components/fiveJBR/footer'
import {StaticImage} from 'gatsby-plugin-image'
import ModalPage from '../../src/components/modalPage'
import SEO from '../../src/components/App/SEO'
import {siteUrl} from '../components/_utils'
import ModalPage1 from '../../src/components/researchComp'
import heroImage from '../images/fiveJBR/heroImage.jpg'
import {useScrollTracker} from 'react-scroll-tracker'
import ReactGA from 'react-ga'
import {useEffect, useState} from "react";
import {getCountry} from "../action";
const shortNumber = require('short-number')

let dollarAmount = 735073.89
const FiveJBR = ({data}) => {
    const [countryISO, setCountryISO] = useState('ae')
    const [short, setShort] = useState(shortNumber(dollarAmount))
    const [symbol, setSymbol] = useState('$')

    useEffect(() => {
        getCountry({amount: dollarAmount}).then((data) => {
            console.log(data)

            if (data.short) {
                setShort(data.short)
            }
            if (data.symbol) {
                setSymbol(data.symbol)
            }
            if (data.iso) {
                setCountryISO(data.iso.toLowerCase())
            }
        })
    }, [])
    if (typeof window !== 'undefined') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useScrollTracker([25, 50, 75, 100], ({scrollY}) => {
            ReactGA.event({
                category: 'scroll',
                action: scrollY,
            })

        })
    }

    const logoComp = (
        <StaticImage
            className="w-24 md:w-32 lg:w-46  align-middle"
            placeholder="tracedSVG"
            src={'../images/fiveJBR/fiveLogo.png'}
            alt={'Azizi '}
        />
    )

    const titleParams = {
        title: `Five JBR - New Project in Jumeirah Beach Residences, Dubai | ${siteUrl}`,
        ogTitle: 'Five JBR - New Project in Jumeirah Beach Residences, Dubai',

        description: 'FIVE Hotel & Resorts have created FIVE Palm Jumeirah and are known for their luxurious levels of services and interiors.',
        ogDescription: 'FIVE Hotel & Resorts have created FIVE Palm Jumeirah and are known for their luxurious levels of services and interiors.',
        keywords: 'five JBR, off plan properties in Five JBR, new launch in JBR, luxury apartments project in Dubai, Apartments for Sale in Five JBR, Off Plan Apartments in JBR, Of Plan Properties for Sale in Five JBR, Apartments for Sale in JBR, Properties for Sale in Five',

        ogImage: heroImage,
        secureImageUrl: heroImage,
        ogImageAlt: 'Five JBR - New Project in Jumeirah Beach Residences, Dubai',

        pageLink: `${siteUrl}fiveJBR/`,
        orUrl: `${siteUrl}fiveJBR/`,
        canonicalRef: `${siteUrl}fiveJBR/`,

    }

    return (
        <>
            <SEO data={titleParams}/>
            <NavHeader logoComp={logoComp}/>
            <HeroArea countryISO={countryISO} short={short} symbol={symbol}/>
            <Connectivity/>
            {/*<ModalPage1/>*/}
            <Amenities/>
            <Plans/>
            <Footer countryISO={countryISO}/>
            <ModalPage/>
        </>
    )
}

export default FiveJBR
