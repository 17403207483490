import {
  buttonCss,
  contentDiv,
  headingText, paraText, buttonCss1,
} from '../../styles/main.module.css'
import { phaseCss } from '../aziziComp/plans.module.css'
import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { averageCss } from '../aziziComp/appartments.module.css'

const Plans = () => {
  return (<div className={phaseCss}>

      <div className={'pb-9 sm:pb-16'}>
        <StaticImage
          width={1500}
          height={600}
          src={'../../images/fiveJBR/fivejbr4.jpg'}
          alt={'photo'}/>
      </div>
      <div className={`${contentDiv} container`}>
        <div>
          <h2 className={headingText}>PAYMENT PLAN</h2>
        </div>

        <div className={'my-10'}>
          <div className="grid grid-cols-1 sm:grid-cols-1 gap-4">
            <div className="text-center">

              <p className={'text-xs mb-2'}>Interest-Free Payment Plan</p>
              <p className={'text-xs mb-2'}>Pay 60% until handover and Start Earning 10%
                Guaranteed ROI
              </p>
              <p className={'text-xs mb-2'}>Pay 40% over 2 years after handover</p>
              <p className={'text-xs mb-2'}>Should you choose to be an end-user, you can opt for a
                30/70 payment plan, with 70% payable on handover</p>

            </div>
          </div>

        </div>

        <div>
          <h2 className={headingText}>PHOTO GALLERY</h2>

          <div className="my-10 grid grid-cols-1 md:mb-4 md:grid-cols-2 lg:grid-cols-2 gap-4">
            <div className="text-center">

              <div>
                <StaticImage
                  className={'m-auto'}
                  placeholder="dominantColor"
                  src={'../../images/fiveJBR/fivejbr6.jpg'}
                  alt={'bottom1'}/>
              </div>

            </div>
            <div className="text-center">
              <div>
                <StaticImage
                  className={'m-auto'}
                  placeholder="dominantColor"
                  src={'../../images/fiveJBR/fivejbr7.jpg'}
                  alt={'bottom1'}/>
              </div>
            </div>

            <div className="text-center">
              <div>
                <StaticImage
                  className={'m-auto'}
                  placeholder="dominantColor"
                  src={'../../images/fiveJBR/fivejbr8.jpg'}
                  alt={'bottom1'}/>
              </div>
            </div>
            <div className="text-center">
              <div>
                <StaticImage
                  className={'m-auto'}
                  placeholder="dominantColor"
                  src={'../../images/fiveJBR/fivejbr9.jpg'}
                  alt={'bottom1'}/>
              </div>
            </div>
            <div className="text-center">
              <div>
                <StaticImage
                  className={'m-auto'}
                  placeholder="dominantColor"
                  src={'../../images/fiveJBR/fivejbr10.jpg'}
                  alt={'bottom1'}/>
              </div>
            </div>
            <div className="text-center">
              <div>
                <StaticImage
                  className={'m-auto'}
                  placeholder="dominantColor"
                  src={'../../images/fiveJBR/fivejbr11.jpg'}
                  alt={'bottom1'}/>
              </div>
            </div>


          </div>
        </div>


        <div className={'mt-12'}>
          <h2 className={headingText}>CURRENT PROMOTIONS</h2>


          <div className={'my-5 hidden m-auto sm:flex justify-around items-center w-3/5'}>
            <div>
              <StaticImage
                className={'m-auto sm:w-20 sm:h-20 w-12 h-12'}
                placeholder="dominantColor"

                src={'../../images/current.png'}
                alt={'photo'}/>
              <p className={' mt-2 text-xxs text-primary-lightGray tracking-widest'}><strong
                className={'text-primary-gray'}>70% PAYABLE<br/>ON HANDOVER</strong></p>

            </div>
            <div>
              <StaticImage
                className={'m-auto sm:w-20 sm:h-20 w-12 h-12'}
                placeholder="dominantColor"

                src={'../../images/current-3.png'}
                alt={'photo'}/>
              <p className={'mt-2 text-xxs text-primary-lightGray tracking-widest'}><strong
                className={'text-primary-gray'}> START EARNING 10% <br/>GUARANTEED ROI</strong>
              </p>
            </div>
            <div>
              <StaticImage
                className={'m-auto sm:w-20 sm:h-20 w-12 h-12'}
                placeholder="dominantColor"

                src={'../../images/current2.png'}
                alt={'photo'}/>
              <p className={'mt-2 text-xxs text-primary-lightGray tracking-widest'}><strong
                className={'text-primary-gray'}>2 YEARS <br/>POST HANDOVER</strong>
              </p>
            </div>
          </div>
          <div className={'my-5 sm:hidden w-2/3 m-auto'}>

            <div className={'flex items-center mb-5'}>
              <StaticImage
                className={'w-12 h-12 mr-3'}
                placeholder="dominantColor"

                src={'../../images/current.png'}
                alt={'photo'}/>
              <p className={' mt-2 text-xxs text-primary-lightGray tracking-widest'}><strong
                className={'text-primary-gray'}>70% PAYABLE<br/>ON HANDOVER</strong></p>

            </div>
            <div className={'flex items-center mb-5'}>
              <StaticImage
                className={'w-12 h-12 mr-3'}
                placeholder="dominantColor"

                src={'../../images/current-3.png'}
                alt={'photo'}/>
              <p className={'mt-2 text-xxs text-primary-lightGray tracking-widest'}><strong
                className={'text-primary-gray'}>START EARNING 10% <br/>GUARANTEED ROI</strong>
              </p>
            </div>
            <div className={'flex items-center'}>
              <StaticImage
                className={'w-12 h-12 mr-3'}
                placeholder="dominantColor"

                src={'../../images/current2.png'}
                alt={'photo'}/>
              <p className={'mt-2 text-xxs text-primary-lightGray tracking-widest'}><strong
                className={'text-primary-gray'}>2 YEARS <br/>POST HANDOVER</strong>
              </p>
            </div>


          </div>


          <div className={'pb-8 pt-8'}>
            <p><a className={buttonCss1} href="#">Enquiry Now</a></p>

          </div>
        </div>


      </div>
      <div className={'pb-9 sm:pb-16'}>
        <StaticImage
          width={1500}
          height={600}
          src={'../../images/fiveJBR/fivejbr5.jpg'}
          alt={'photo'}/>
      </div>

      <div className={`${contentDiv} container`}>
        <div>
          <h2 className={headingText}>APARTMENTS</h2>
          <p className={paraText}>Choose from studios, 3 and 4 BR fully furnished apartments and 5 BR
            penthouses. You can earn guaranteed 10% ROI on the properties if you give them to FIVE Hotel
            Group to manage</p>
        </div>

        <div className={averageCss}>
          <p><strong>Studio </strong>: apartment 575 sqft- 720 sqft</p>
          <p><strong>3 BR</strong> : apartment – 2,346 sqft – 2,454 sqft</p>
          <p><strong>4 BR</strong> : apartment – 5,225 sqft</p>
          <p><strong>5 BR</strong> : apartments- duplex apartment -9,587.93 sqft</p>
        </div>

      </div>

    </div>
  )
}

export default Plans
