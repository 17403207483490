import {
  headerSection,
  mainSection,
  heroText,
} from './heroArea.module.css'

import FormLayout from './form'
import * as React from 'react'
import { buttonCss1, contentDiv, headingText, paraText } from '../../styles/main.module.css'

const HeroArea = ({countryISO, short, symbol}) => {
  return (
    <>
      <div className={mainSection}>
        <section
          className={`${headerSection} text-center pt-4 bg-center md:bg-center `}
        >
          <div className={'text-white text-center relative my-7'}>
            <div>
              <div className={`mb-5 m-auto  leading-6 text-center  tracking-widest ${heroText}`}>
                INVEST IN A LUXURY 5 STAR <br/>HOTEL APARTMENTS
              </div>
            </div>
            <div>
              <p className={'m-auto mb-2 text-xs sm:text-sm md:max-w-xl w-2/3'}>
                <p className={'bg-primary-gold p-2 inline-block'}>Start Earning 10% Guaranteed ROI</p>
              </p>
            </div>
            <div>
              <p className={'m-auto mb-5 text-xs sm:text-sm md:max-w-xl w-2/3'}>
                Pay 40% over 2 years after handover
              </p>
            </div>
            <div>
              <p className={'m-auto text-xs sm:text-sm tracking-wider md:max-w-xl w-3/4'}>
                INVESTMENT STARTS FROM:
              </p>
            </div>
            <div>
              <h1 className={'m-auto text-3xl font-bold md:max-w-xl w-3/4'}>
                {/*<span className={'text-lg'}>( $7,35,073.89 / €6,34,784.09)</span>*/}
                {symbol} {short}
              </h1>
            </div>

          </div>
        </section>
        <FormLayout countryISO={countryISO}/>

        <div className={'py-12'}>
          <div className={`${contentDiv} container`}>
            <h2 className={headingText}>
              FULLY-FURNISHED HOTEL SERVICED 3 & 4-BEDROOM APARTMENTS WITH BEACH ACCESS
            </h2>
            <ul className={paraText}>
              <li className={'leading-6'}>
                Interest-Free Payment Plan
              </li>
              <li>
                Handover: 2023
              </li>
              <li className={'leading-6'}>
                Owners can give the property to FIVE to manage
              </li>
              <li className={'leading-6'}>
                You can opt for a 30/70 payment plan, with 70%
                payable on handover
              </li>
              <li className={'leading-6'}>
                Fully Furnished apartments
              </li>
              <li className={'leading-6'}>
                A La Carte Services available
              </li>
            </ul>
          </div>

          <div className={'py-10 text-center'}>
            <p><a className={buttonCss1} href="#">Open Brochure</a></p>
          </div>

        </div>
      </div>
    </>
  )
}

export default HeroArea
