import {
    contentDiv,
    firstGrid,
    headingText, eminityImageText,
    paraText, buttonCss,
} from "../../styles/main.module.css";
import * as React from "react";
import image9 from "../../images/waves/pool.png";
import image10 from "../../images/waves/smartHome.png";
import image11 from "../../images/waves/indoorChild.png";
import image12 from "../../images/waves/bbq.png";
import image13 from "../../images/waves/fitness.png";
import image14 from "../../images/waves/cinema.png";
import {StaticImage} from "gatsby-plugin-image";


const Eminity = () => {
    return (<>
        <div className={'pb-9 sm:pb-16'}>
            <StaticImage
                width={1500}
                height={600}
                src={'../../images/fiveJBR/fivejbr2.jpg'}
                alt={'photo'}/>
        </div>
        <div className={`${contentDiv} container`}>
            <div>
                <h2 className={headingText}>AMENITIES THAT YOU WILL LOVE!</h2>
                <p className={paraText}>Crafted to perfection by The FIVE group of hotels and resorts, FIVE JBR is an
                    amazing property with exquisitely designed interiors. Luxurious, plush and complete with top-notch
                    amenities, FIVE JBR has two separate entrances for residences and the hotel. Residents will have
                    beach access and enjoy facilities like beachside restaurants, kids club, beach spa, and indoor spa
                    pool, and more. Here’s a list of just some of the exclusive amenities you will have access to:</p>
            </div>

            <div className={'sm:my-20 my-10'}>
                <div
                    className={`sm:gap-y-24 w-3/4 m-auto grid grid-cols-2 md:mb-4 md:grid-cols-3 lg:grid-cols-3 gap-4 ${firstGrid}`}>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}>
                            <img src={image9}
                                 alt=""/>
                        </div>
                        <p className={eminityImageText}><span>Childrens & <br/> Social Pools</span></p>

                    </div>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image10}/></div>
                        <p className={eminityImageText}><span>Private<br/>Beach</span></p>

                    </div>

                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image11}/></div>
                        <p className={eminityImageText}><span>Water<br/>Sports</span></p>

                    </div>


                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image12}/></div>

                        <p className={eminityImageText}><span> Gourmet Food<br/> HotSpot</span></p>

                    </div>

                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image13}/></div>
                        <p className={eminityImageText}><span>Fitness <br/> Centre</span></p>


                    </div>

                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image14}/></div>

                        <p className={eminityImageText}><span>Easy Access<br/>to Ain Dubai</span></p>

                    </div>


                </div>


            </div>

        </div>
    </>)
}

export default Eminity
