import { FaWhatsapp } from 'react-icons/fa'
import * as React from 'react'
import FooterFormLayout from '../aziziComp/footerForm'
import footerImage from '../../images/BNW_logo_png.png'
import { StaticImage } from 'gatsby-plugin-image'

const Footer = ({countryISO}) => {
  return (<>
    <div className={'mb-16 mt-8'}>
      <div className={'pb-9 sm:pb-12'}>
        <StaticImage
          width={1500}
          height={600}
          src={'../../images/fiveJBR/fivejbr3.jpg'}
          alt={'photo'}/>
      </div>

    </div>
    <FooterFormLayout countryISO={countryISO} showPara={true} heading={'BOOK A FREE CONSULTATION'} buttonName={'Submit'}/>
    <div className={'pt-10 text-center container'}>
      <div className={'pb-16'}>
        <p><a
          className={'inline-flex items-center py-3 px-8 border text-xs sm:text-sm  text-primary-green border-primary-green '}
          href="#"><FaWhatsapp className={'sm:text-2xl text-xl mr-3  text-primary-green '}/> We
          Are Online</a>
        </p>

      </div>


    </div>

    <div className={'bg-primary-gray'}>
      <div className={'container  py-16'}>
        <img className={'m-auto'} src={footerImage}
             alt=""/>
      </div>

    </div>
  </>)
}

export default Footer
