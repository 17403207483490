import {modal} from "../styles/main.module.css";
import Form from "../components/aziziComp/footerForm"
import * as React from "react";
import {input} from "./aziziComp/form.module.css";
import {StaticImage} from "gatsby-plugin-image";
// import 'flag-icon-css/css/flag-icon.min.css';
// import ReactFlag from "./reactFlagsComp"

setInterval(() => {
    document.getElementById("myForm").style.display = "block";
}, 80000000);


const ModalForm = () => {
    return (
        <div id={"myForm"} className={'hidden'}>
            <div className={modal}>

                <div className={`bg-white m-auto block mt-20 sm:mt-10 md:max-w-lg w-11/12`}>
                    <button onClick={() => {
                        document.getElementById("myForm").style.display = "none";
                    }} className={`z-10 pr-4 text-right absolute text-3xl md:max-w-lg w-11/12`}>&times;</button>

                    <StaticImage
                        placeholder="dominantColor"
                        src={'../images/aziziBackground.jpg'}
                        alt={'photo'}/>

                    <div className={'p-4'}>
                        <div className={'sm:text-2xl text-center text-2xl text-primary-gray'}>
                            FREE DOWNLOAD
                        </div>

                        <div className={'text-xs mt-2 text-center text-primary-lightGray'}>
                            Brochure & Floor Plans
                        </div>

                        <form>
                            <div className={'my-4 sm:mx-10'}><input
                                className={`w-full  bg-gray-50 border-opacity-20 py-2 px-4 text-primary-gray text-sm `}
                                type="text" placeholder="Name" id={'name'} required={true}/></div>
                            <div className={'flex my-4 sm:mx-10'}>


                                {/*<select className={'w-1/6 border border-r-0 bg-gray-50 border-opacity-20 py-2 px-4 text-primary-gray text-sm '} id={"select"} name="plan">*/}
                                {/*    {countryList.map((x, i) => {*/}
                                {/*        return (<option>*/}


                                {/*        </option>)*/}
                                {/*    })}*/}


                                {/*</select>*/}
                                {/*<ReactFlag footerFlag={true}/>*/}


                                <input
                                    className={`w-full border border-l-0 bg-gray-50 border-opacity-20 py-2 px-4 text-primary-gray text-sm   `}
                                    type="text" placeholder="Mobile" id={'mobile'} required={true}/>
                            </div>
                            <div className={'my-4 sm:mx-10'}><input
                                className={`w-full border bg-gray-50 border-opacity-20 py-2 px-4 text-primary-gray text-sm `}
                                type="text" placeholder="Email" id={'email'} required={true}/></div>
                            <div className={'my-4 sm:mx-10'}>
                                <button
                                    className={'w-full text-sm hover:bg-primary-gray text-primary-gray border border-primary-gray py-2 px-4 hover:text-white'}
                                    type="submit">
                                    Submit
                                </button>
                            </div>

                        </form>

                    </div>


                </div>

            </div>

        </div>
    )
}

export default ModalForm
