import {floatingBox, input, telinputContainer, loaderText} from './form.module.css'
import * as React from 'react'
import {useForm} from 'react-hook-form'


import {addForm} from '../../action'
import ReactFlag from '../reactFlagsComp-back'
import {siteUrl} from '../_utils'
import {ImSpinner} from "react-icons/im";

const FormLayout = (props) => {

    const {register, handleSubmit, formState: {errors}} = useForm()
    const [mobile, setMobile] = React.useState()
    const [loading, setLoading] = React.useState(false)
    let [phoneCode, setCode] = React.useState('');

    const onSubmit = async (data) => {
        console.log(data, mobile)
        if (!mobile) {
            alert("Mobile Number is required");
            return;
        }
        if (!phoneCode) {
            alert("Phone Code  is required");
            return;
        }

        setLoading(true)
        let x = await addForm({...data, mobile: mobile, phoneCode})
        setLoading(false)

        if (!x.error) {
            window.location.href = `${siteUrl}thankuPage`
        } else {
            console.log(x)
        }


    }

    return (
        <div className={`sm:px-5 md:max-w-2xl w-11/12 m-auto bg-white ${floatingBox}`}>
            <div className={'p-6 '}>

                <div className={'text-l text-center text-sm text-primary-gray'}>
                    SEND US A MESSAGE
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={'my-4'}><input  {...register('name')}
                                                    className={`w-full border border-primary-gray border-opacity-20 py-2 px-4 text-primary-gray text-sm `}
                                                    type="text" placeholder="Name" required={true}/></div>

                    <div className={'flex my-4'}>
                        <div className={`border border-primary-gray border-opacity-20 ${telinputContainer}`}>
                            <ReactFlag countryISO={props.countryISO} footerFlag={false} flagValue={(value) => {
                                if (value && value.mobile) {
                                    setMobile(value.mobile);
                                    setCode(value.code);
                                } else {
                                    if (value.mobile) {
                                        setMobile('')
                                    }
                                    if (value.code) {
                                        setCode('')
                                    }
                                }
                            }}/>
                        </div>

                    </div>
                    <div className={'my-4'}><input {...register('email')}
                                                   className={`w-full border border-primary-gray border-opacity-20 py-2 px-4 text-primary-gray text-sm `}
                                                   type="email" placeholder="Email" id={'email'} required={true}/></div>
                    <div className={'my-4'}>
                        <button disabled={loading}
                                style={{flex: 1, alignContent: 'center'}}
                                className={'w-full text-sm bg-primary-orange py-2 px-4 text-white'}
                                type="submit">
                            <span className={loaderText}>{loading && <ImSpinner className={'icon-spin '}/>}</span>

                            <span>Send</span>
                        </button>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default FormLayout
