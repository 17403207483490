import {
    footerFloatingBox,
    input,
} from './form.module.css'
import * as React from 'react'
// import ReactFlag from '../reactFlagsComp'
import {useForm} from 'react-hook-form'
import {addForm} from '../../action'
import {siteUrl} from '../_utils'
import {telinputContainer} from "../fiveJBR/form.module.css";
import ReactFlag from "../reactFlagsComp-back";

const FormLayout = (props) => {
    const {register, handleSubmit, formState: {errors}} = useForm()
    const [mobile, setMobile] = React.useState()
    let [phoneCode, setCode] = React.useState('');

    const onSubmit = async (data) => {
        console.log(data, mobile)
        if (!mobile) {
            alert("Mobile Number is required");
            return;
        }
        if (!phoneCode) {
            alert("Phone Code  is required");
            return;
        }

        let x = await addForm({...data, mobile: mobile,phoneCode,form:''})
        if (!x.error) {
            window.location.href = `${siteUrl}thankuPage`
        } else {
            console.log(x)
        }


    }

    return (<div className={`sm:px-5 md:max-w-2xl w-11/12 m-auto bg-white ${footerFloatingBox}`}>
            <div className={'p-10'}>

                <div className={'sm:text-2xl text-center text-sm text-primary-gray'}>
                    {props.heading}
                </div>

                {props.showPara && <div className={'text-xs mt-2 text-center text-primary-lightGray'}>
                    To Download Brochure & Floor Plans
                </div>}

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={'my-4 sm:mx-10'}><input
                        className={`w-full  bg-gray-50 border-opacity-20 py-2 px-4 text-primary-gray text-sm `}
                        type="text" placeholder="Name" id={'name'} {...register('name')} required={true}/></div>
                    <div className={'my-4 sm:mx-10'}>

                        <div className={` ${telinputContainer}`}>
                            <ReactFlag countryISO={props.countryISO} footerFlag={true} flagValue={(value) => {
                                if(value && value.mobile){
                                    setMobile(value.mobile);
                                    setCode(value.code);
                                }else{
                                    if(value.mobile){
                                        setMobile('')
                                    }
                                    if(value.code){
                                        setCode('')
                                    }
                                }
                            }}/>
                        </div>

                    </div>
                    <div className={'my-4 sm:mx-10'}><input
                        className={`w-full border bg-gray-50 border-opacity-20 py-2 px-4 text-primary-gray text-sm `}
                        type="text" placeholder="Email" id={'email'} {...register('email')} required={true}/></div>
                    <div className={'my-4 sm:mx-10'}>
                        <button
                            className={'w-full text-sm hover:bg-primary-gray text-primary-gray border border-primary-gray py-2 px-4 hover:text-white'}
                            type="submit">
                            {props.buttonName}
                        </button>
                    </div>

                </form>

            </div>

        </div>
    )
}

export default FormLayout
