import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {
  contentDiv,
  firstGrid,
  gridText,
  headingText, iconLine,
  minClass,
  minTextClass,
  paraText,
} from '../../styles/main.module.css'
import mall from '../../images/waves/mall.png'
import city from '../../images/mediaCity.png'
import opera from '../../images/waves/opera.png'
import marina from '../../images/marina.png'
import internet from '../../images/internetCity.png'
import palm from '../../images/THE-PALM-JUMEIRAH.png'

import image5 from '../../images/DOWNTOWN-DUBAI.png'
import image7 from '../../images/DUBAI-INTERNATIONAL.png'

const Connectivity = () => {
  return (<>
    <div className={'pb-9 sm:pb-16'}>
      <StaticImage
        width={1500}
        height={600}
        src={'../../images/fiveJBR/fivejbr1.jpg'}
        alt={'photo'}

      />
    </div>
    <div className={`${contentDiv} container`}>
      <div>
        <h2 className={headingText}>LOCATION MAP
        </h2>
        <p className={paraText}>FIVE Hotel & Resorts have created FIVE Palm Jumeirah and are known for their
          luxurious levels of services and interiors. FIVE JBR is set to be an iconic addition on the JBR
          beach and is the last property on the beach in this area.</p>
      </div>
      <div className={'sm:my-20 my-10'}>

        <div
          className={`grid grid-cols-2  sm:grid-cols-3 md:grid-cols-4 gap-0 sm:gap-y-20 sm:gap-x-12 ${firstGrid}`}>
          <div className={'flex flex-col items-center'}>
            <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                 src={marina}/></div>
            <p className={gridText}>DUBAI MARINA</p>
            <p className={minClass}>03</p>
            <p className={minTextClass}>MIN</p>
            <div className={'sm:hidden pt-0 py-4 w-2/3'}>
              <span className={`${iconLine} `}/>
            </div>
          </div>
          <div className={'flex flex-col items-center'}>
            <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                 src={internet}/></div>
            <p className={gridText}>DUBAI INTERNET CITY</p>
            <p className={minClass}>08</p>
            <p className={minTextClass}>MIN</p>
            <div className={'sm:hidden pt-0 py-4 w-2/3'}>
              <span className={`${iconLine} `}/>
            </div>
          </div>

          <div className={'flex flex-col items-center'}>
            <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                 src={city}/></div>
            <p className={gridText}>DUBAI MEDIA CITY</p>
            <p className={minClass}>08</p>
            <p className={minTextClass}>MIN</p>
            <div className={'sm:hidden pt-0 py-4 w-2/3'}>
              <span className={`${iconLine} `}/>
            </div>
          </div>
          <div className={'flex flex-col items-center'}>
            <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                 src={palm}/></div>
            <p className={gridText}>PALM JUMEIRAH</p>
            <p className={minClass}>10</p>
            <p className={minTextClass}>MIN</p>

            <div className={'sm:hidden pt-0 py-4 w-2/3'}>
              <span className={`${iconLine} `}/>
            </div>

          </div>


          <div className={'flex flex-col items-center'}>
            <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                 src={opera}/></div>
            <p className={gridText}>MALL OF EMIRATES</p>
            <p className={minClass}>12</p>
            <p className={minTextClass}>MIN</p>

            <div className={'sm:hidden pt-0 py-4 w-2/3'}>
              <span className={`${iconLine} `}/>
            </div>
          </div>


          <div className={'flex flex-col items-center'}>
            <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                 src={mall}/></div>
            <p className={gridText}>BURJ KHALIFA</p>
            <p className={minClass}>18</p>
            <p className={minTextClass}>MIN</p>

            <div className={'sm:hidden pt-0 py-4 w-2/3'}>
              <span className={`${iconLine} `}/>
            </div>

          </div>


          <div className={'flex flex-col items-center'}>
            <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                 src={image5}/></div>
            <p className={gridText}>DOWNTOWN DUBAI</p>
            <p className={minClass}>20</p>
            <p className={minTextClass}>MIN</p>

          </div>


          <div className={'flex flex-col items-center'}>
            <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                 src={image7}/></div>
            <p className={gridText}>DUBAI INTERNATIONAL AIRPORT</p>
            <p className={minClass}>24</p>
            <p className={minTextClass}>MIN</p>


          </div>

        </div>

      </div>

    </div>
  </>)
}

export default Connectivity
